/* сброс стилей браузера */
* {
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
/* конец кода сброса стилей браузера */
body {
  font-size: 1rem;
  line-height: 1.3;
  font-family: Helvetica, sans-serif;
  background-color: #ece5ce;
  width: 100%;
  min-width: 200px;
  max-width: 770px;
  margin: 0 auto 40px auto;
}
header {
  font-size: 2.1rem;
  margin: 20px 0 20px 192px;
  text-transform: uppercase;
}
nav {
  float: left;
  width: 10%;
  position: fixed;
  line-height: 1.5;
  margin-left: 10px;
}
.content {
  margin: 0 10px 0 120px;
  border-left: solid 4px black;
  padding-left: 37px;
  font-size: 0.95rem;
  min-height: 83vh;
  text-align: justify;
}
footer {
  font-size: 0.9rem;
  position: fixed;
  right: 10px;
  bottom: 10px;
  background-color: #ece5ce;
  padding: 5px;
  border-radius: 30px;
}
footer a:link {
  color: #7f5b32;
}
footer a:hover {
  color: #5e6b62;
}
a:link {
  color: black;
}
a:visited {
  color: black;
}
a:hover {
  color: #5e6b62;
}
ul {
  text-transform: uppercase;
}
p {
  margin-bottom: 10px;
}
li:hover {
  transform: scale(1.1);
}
img {
  margin: 100px 0 0 40px;
}
img:first-child {
  margin-top: 77px;
}
figure {
  text-align: center;
}
figcaption {
  width: 305px;
  margin-left: 40px;
  text-align: center;
}
iframe {
  margin: 77px 0 0 40px;
}
iframe:first-child {
  margin-top: 77px;
}
.about .aboutl,
.dates .datesl,
.mixes .mixesl,
.photos .photosl,
.videos .videosl,
.read .readl,
.contact .contactl {
  color: #5e6b62;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 667px) {
  header {
    margin: 20px 0;
    font-size: 3rem;
    text-align: center;
  }
  nav {
    float: none;
    position: static;
    font-size: 1.5rem;
    width: auto;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 20px;
  }
  /* new: width, height; */
  .content {
    margin: 0;
    padding: 2rem;
    border: none;
    border-top: solid 4px black;
    border-bottom: solid 4px black;
    font-size: 1.6rem;
    margin-bottom: 20px;
    min-height: 53vh;
  }
  /* new: width, height; */
  .content a {
    font-size: 1.6rem;
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  p {
    margin-bottom: 20px;
  }
  footer {
    font-size: 1.4rem;
    position: static;
    text-align: center;
  }
  img {
    margin: 0;
  }
  img:first-child {
    margin: 0;
  }
  /* figure:last-child {
    padding-bottom: 87px;
  } */
  iframe {
    display: block;
    margin: 2rem auto;
    width: 100%;
  }
  iframe:first-child {
    margin: 0;
  }
  /* new: auto; */
  figcaption {
    margin: 0 auto 1rem;
    font-size: 1rem;
    line-height: 1.1;
  }
  li:hover {
    transform: none;
  }
}
